import AboutUs from "../../components/Home/AboutUs";

const AboutPage = () => {
    return (
        <>
            
            
            <AboutUs />
            
        </>
    );
};
export default AboutPage;